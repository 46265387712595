<template>
  <div></div>
</template>
<script>
export default {
  name: 'DeviceAuth',
  data() {
    return {
    }
  },
  mounted:function(){
    this.verifyDevice();
  },
  methods:{
    verifyDevice() {
      let url = new URL(window.location.href);
      this.$http.get(this.createApiUrlChestNut('users/device-auth/'+url.search)).then(() => {
        this.$router.push({ name: 'Signin', params: {authStatus: 'success' }}).catch(() => {});
      },err => {
        this.$router.push({ name: 'Signin', params: {authStatus: err.body.message }}).catch(() => {});
      });
    },
  },
}
</script>
